import { useRef } from 'react';
import { useOnClickOutside } from 'usehooks-ts';
import Icon from '../../generic/Icon';
import { toBooleanState } from '../../utils/useBooleanState';
import usePopoverInsideScreen from '../../utils/usePopoverInsideScreen';
import useStateWithSideEffect from '../../utils/useStateWithSideEffect';
import * as style from './ProductTags.module.css';

export interface Props {
    tags?: Sticker[];
}

export interface Sticker {
    description: string;
    group: { color: string };
    icon: string;
    sustainability: boolean;
    title: string;
}

export default function ProductTags({ tags }: Props) {
    if (!tags?.length) {
        return null;
    }
    return (
        <ul className={style.tagList}>
            {tags.map((tag) => (
                <Tag key={tag.title} {...tag} />
            ))}
        </ul>
    );
}

function Tag(props: Sticker) {
    const [isOpen, setIsOpen] = toBooleanState(
        useStateWithSideEffect(false, (open) => {
            if (open)
                gtag('event', 'modal_view', {
                    modal_name: 'ProductTag',
                    link_text: props.title,
                });
        }),
    );
    const containerRef = useRef<HTMLLIElement>(null);
    useOnClickOutside(containerRef, setIsOpen.toFalse);

    return (
        <li ref={containerRef} style={{ '--tag-color': props.group.color }}>
            <button onClick={setIsOpen.toggle}>{props.title}</button>
            {isOpen && <StickerContainer {...props} />}
        </li>
    );
}

function StickerContainer({ title, icon, description }: Sticker) {
    const popoverRef = usePopoverInsideScreen<HTMLDivElement>('var(--product__layout-padding)');

    return (
        <div className={style.popup} ref={popoverRef}>
            <div className={style.title}>
                <h3>{title}</h3>
                {!!icon && <Icon className={`icon-${icon}`}></Icon>}
            </div>
            <p>{description}</p>
        </div>
    );
}
