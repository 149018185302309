import { useContext, useEffect, useState } from 'react';
import { useDebounceValue } from 'usehooks-ts';
import { CartContext } from '../cart/CartContext';
import ErrorBox from '../generic/ErrorBox';
import Icon from '../generic/Icon';
import { TrackingContext } from '../generic/TrackingContext';
import ForgivingNumberInput from '../generic/forms/ForgivingNumberInput';
import useBooleanState from '../utils/useBooleanState';
import usePropState from '../utils/usePropState';
import * as style from './QuantityPicker.module.css';

interface Props {
    onQuantityChanged?(): Promise<unknown>;
    quantity: number;
    entryNumber: number;
    maxQuantity?: number;
}

export default function QuantityPicker({ onQuantityChanged, entryNumber, quantity, maxQuantity }: Props) {
    const [qty, setQty] = usePropState(quantity);
    const [throttledQty] = useDebounceValue(qty, 750);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useBooleanState();
    const cart = useContext(CartContext);
    const { item_id, index, item_list_id, item_list_name, value } = useContext(TrackingContext);

    const updateCart = async (qty: number) => {
        setError(null);
        setIsLoading.toTrue();

        try {
            const { quantityAdded } = qty === 0 ? await cart.deleteCartEntry(entryNumber) : await cart.updateCartEntry(entryNumber, qty);

            if (quantityAdded < 0) {
                gtag('event', 'remove_from_cart', {
                    items: [{ item_id, index, quantity: -quantityAdded }],
                    item_list_id,
                    item_list_name,
                });
            } else {
                gtag('event', 'add_to_cart', {
                    items: [{ item_id, index, quantity: quantityAdded }],
                    item_list_id,
                    item_list_name,
                    value,
                });
            }
            await onQuantityChanged?.();
        } catch (error: any) {
            setError(error);
        } finally {
            setIsLoading.toFalse();
        }
    };

    useEffect(() => {
        if (throttledQty < 0) {
            setQty(0);
        } else if (maxQuantity && throttledQty > maxQuantity) {
            setQty(maxQuantity);
        } else if (throttledQty !== quantity) {
            updateCart(throttledQty);
        }
    }, [throttledQty]);

    return (
        <div className={style.container}>
            <div className={style.quantityPicker}>
                <button
                    type="button"
                    disabled={isLoading || qty < 1}
                    title="Reduser antall"
                    aria-label="Reduser antall"
                    onClick={() => setQty((q) => q - 1)}
                >
                    <Icon className="icon-decrease" />
                </button>
                <ForgivingNumberInput
                    aria-label="Antall du ønsker å kjøpe"
                    className={isLoading ? style.busy : undefined}
                    value={qty}
                    min={0}
                    max={maxQuantity}
                    onChange={setQty}
                />
                <button
                    type="button"
                    disabled={isLoading || (!!maxQuantity && qty >= maxQuantity)}
                    title="Øk antall"
                    aria-label="Øk antall"
                    onClick={() => setQty((q) => q + 1)}
                >
                    <Icon className="icon-increase" />
                </button>
            </div>
            <ErrorBox errors={error} />
        </div>
    );
}
