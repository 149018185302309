import { useState } from 'react';
import * as api from '../api';
import { wishlistAPI } from '../config';
import ErrorBox from '../generic/ErrorBox';
import SubmitButton from '../generic/forms/SubmitButton';
import Icon from '../generic/Icon';
import eventTargetValue from '../utils/eventTargetValue';
import preventDefault from '../utils/preventDefault';
import useBooleanState from '../utils/useBooleanState';

export interface List {
    name: string;
    code: string;
}

export default function ProductListForm({ onCreateList }: { onCreateList(list: List): void }) {
    const [name, setName] = useState('');
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useBooleanState();
    const [formVisible, setFormVisible] = useBooleanState(false);

    const createList = async () => {
        setError(null);
        setIsLoading.toTrue();

        try {
            const data = await api.post(wishlistAPI.getWishlistsURL(), { name: name });
            onCreateList(data);
            setName('');
            setFormVisible.toFalse();
        } catch (e: any) {
            setError(e);
        } finally {
            setIsLoading.toFalse();
        }
    };

    return (
        <div className="new-list">
            {!formVisible ? (
                <button className="new-list-btn btn-secondary" onClick={setFormVisible.toTrue}>
                    Lag ny liste <Icon className={'icon-add'}></Icon>
                </button>
            ) : (
                <>
                    <h2>Opprett ny liste</h2>
                    <ErrorBox errors={error} />
                    <form onSubmit={preventDefault(createList)}>
                        <input
                            required
                            type="text"
                            value={name}
                            onChange={eventTargetValue(setName)}
                            placeholder="Skriv navn på listen"
                            className="form-control"
                            disabled={isLoading}
                        />
                        <SubmitButton isLoading={isLoading} disabled={!name}>
                            Legg til <span className="sr-only">i ny liste</span>
                        </SubmitButton>
                    </form>
                </>
            )}
        </div>
    );
}
