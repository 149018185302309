import { useCallback, useState } from 'react';
import * as api from '../api';
import { productAPI } from '../config';
import ErrorBox from '../generic/ErrorBox';
import InfoBox from '../generic/InfoBox';
import Spinner from '../generic/Spinner';
import { ProductCardDetails } from '../product/ProductCard';
import ProductMiniCard from '../product/ProductMiniCard';
import { StoreResponse } from '../storelocator/StoreLocator';
import StoreLocatorForm, { Coords } from '../storelocator/StoreLocatorForm';
import useBooleanState from '../utils/useBooleanState';
import StoreStockInfo from './StoreStockInfo';
import * as style from './store-stock-locator.module.css';

interface Props {
    product: ProductCardDetails;
}

export default function StoreStockLocator({ product }: Props) {
    const [infoMessage, setInfoMessage] = useState<string>();
    const [error, setError] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useBooleanState(true);
    const [searchResult, setSearchResult] = useState<StoreResponse | null>(null);

    const onLocationChange = useCallback(
        ({ latitude, longitude }: Coords) => {
            setInfoMessage(undefined);
            setIsLoading.toTrue();
            setError(null);
            setSearchResult(null);

            api.get(productAPI.stockURL(product.code), {
                pageSize: '10',
                currentPage: '0',
                fields: 'BASIC',
                latitude: latitude.toString(),
                longitude: longitude.toString(),
            })
                .then(setSearchResult)
                .catch(setError)
                .finally(setIsLoading.toFalse);
        },
        [product],
    );

    return (
        <div className={`${style.storeStockLocator} dialog-split`}>
            <ProductMiniCard product={product} className="dialog-info" />
            <div className="store-locator__container dialog-main">
                <div className="store-locator">
                    <h1>Nærmeste butikk med varen på lager</h1>
                    <StoreLocatorForm
                        onLocationChange={onLocationChange}
                        onFail={setError}
                        onLocationFail={(info) => {
                            setInfoMessage(info);
                            setIsLoading.toFalse();
                        }}
                    />
                    <ErrorBox errors={error} />
                    <InfoBox message={infoMessage} className="margin-bottom" />
                    {!searchResult ? (
                        <p className={style.info}>Søk på ønsket butikk for å se lagerstatus.</p>
                    ) : !searchResult.stores?.length ? (
                        <p className={style.info}>Vi fant dessverre ikke denne varen på lager i noen av våre butikker.</p>
                    ) : (
                        <>
                            <p>{searchResult.betweenStoresMessage}</p>
                            <ul className="store-list">
                                {searchResult.stores.map((store) => (
                                    <StoreStockInfo key={store.pointOfService.name} store={store.pointOfService} stockInfo={store.stockInfo} />
                                ))}
                            </ul>
                        </>
                    )}
                    <Spinner isSpinning={isLoading} />
                </div>
            </div>
        </div>
    );
}
