import { ReactPageFunction } from '../../../js/cloudflare/types';
import { productAPI } from '../../../js/config';
import GiftcardPage from '../../../js/giftcard/GiftCardPage';
import HeaderFooterLayout, { StaticLayoutProps } from '../../../js/layout/HeaderFooterLayout';
import { isGiftCard } from '../../../js/model/Product';
import ProductDetailsPage, { DetailedProduct } from '../../../js/product/productdetails/ProductDetailsPage';
import { createMetaDescription } from '../../../js/product/productdetails/structuredProductData';
import { setCacheControl } from '../../../js/utils/responseHelpers';

interface Props {
    product: DetailedProduct;
}

const CACHE_MINUTES = 5;
export const onRequestGet: ReactPageFunction<'[[slug]]/p/[id]', Props> = async ({ request, params, next, renderToResponse, data }) => {
    const url = new URL(request.url);
    const productId = params.id;

    const response = await data.fetchFromBackend(`${productAPI.productURL(productId)}?${new URLSearchParams({ fields: 'FULL' })}`);

    if (response.status === 404) return next();

    if (response.status !== 200) throw new Error(`Request to ${productAPI.productURL(productId)} responded with ${response.status}`);

    const product = await response.json<DetailedProduct>().catch(() => undefined);

    if (!product) return next();

    if (url.pathname !== product.url) {
        return Response.redirect(new URL(product.url, url), 301);
    } else {
        return renderToResponse({
            product,
        }).then(setCacheControl(`public, max-age=${CACHE_MINUTES * 60}, stale-while-revalidate=30`));
    }
};

export default function ({ product }: Props) {
    switch (product.code) {
        case 'gavekort':
            return <GiftcardPage productId={product.code} gavekort={product} />;
        case 'egavekort':
            return <GiftcardPage productId={product.code} egavekort={product} />;
        default:
            return <ProductDetailsPage product={product} />;
    }
}

export function StaticLayout({ assets, props: { product }, url }: StaticLayoutProps<Props>) {
    const description = createMetaDescription(product);
    const canonicalUrl = new URL(product.url, url).toString();
    return (
        <HeaderFooterLayout
            assets={assets}
            className="page-productDetails"
            title={product.name}
            page_title={isGiftCard(product) ? 'Gavekort' : 'Produkt'}
            canonical={canonicalUrl}
            meta={[
                { name: 'description', content: description },
                { property: 'og:locale', content: 'nb_NO' },
                { property: 'og:type', content: 'website' },
                { property: 'og:site_name', content: 'vinmonopolet.no' },
                { property: 'twitter:card', content: 'summary_large_image' },
                { property: 'og:description', content: description },
                { property: 'og:title', content: product.name },
                { property: 'og:url', content: canonicalUrl },
                { property: 'og:image', content: product.images?.find((i) => i.format == 'superZoom')?.url },
            ]}
        />
    );
}
