// extracted by mini-css-extract-plugin
var _1 = "block-eVM_dgVs";
var _2 = "content-item-IxyurbKe";
var _3 = "content-item-IxyurbKe";
var _4 = "horizontal-fZDqj1NP";
var _5 = "label-YIR4eD4S";
var _6 = "properties-dDnT0pW8";
var _7 = "properties-item-uAKhCNWo";
var _8 = "properties-list-ZZr8cDyt";
var _9 = "properties-item-uAKhCNWo";
var _a = "properties-list-ZZr8cDyt";
var _b = "style-button-wXT0Qkqn";
var _c = "style-label-PbnTUFH3 label-YIR4eD4S";
var _d = "style-popover-CYV9gK2D";
var _e = "style-button-wXT0Qkqn";
var _f = "style-label-PbnTUFH3 label-YIR4eD4S";
var _10 = "style-popover-CYV9gK2D";
var _11 = "wrapper-MesGNCF2";
export { _1 as "block", _2 as "content-item", _3 as "contentItem", _4 as "horizontal", _5 as "label", _6 as "properties", _7 as "properties-item", _8 as "properties-list", _9 as "propertiesItem", _a as "propertiesList", _b as "style-button", _c as "style-label", _d as "style-popover", _e as "styleButton", _f as "styleLabel", _10 as "stylePopover", _11 as "wrapper" }
